import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createAssetIdToken, createToken } from "../../scripts/utils";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../contexts/StateHolder";
import { getAsset } from "../../scripts/dataHandlers";
import axios from "axios";
import { usePlayerSetting } from "../../Shared/Hooks/player-setting-hook";
import useWindowDimensions from "../../Shared/Hooks/WindowDimension";

//const { REACT_APP_RADIANT_LICENSE } = process.env;

export default function RadiantPlayer(props) {
	const { chosenItem, language, key, setChosenItem, assetProperty, user } = useMyContext();

	console.log("props", props);

	const dimension = useWindowDimensions();

	//console.log(props);
	const rmpPlayer = useRef();

	const history = useHistory();

	const [loading, setLoading] = useState(true);

	// Setup translate function
	const { t } = useTranslation();
	console.log(`chosenItem`, chosenItem, props.isSerie);

	const { asset } = useParams();
	const [state, getPlayerSetting] = usePlayerSetting();

	useEffect(() => {
		if (props?.playerConfig && props?.organizationId && rmpPlayer.current) {
			setLoading(true);
			getPlayerSetting(props?.playerConfig);

			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.playerConfig, props?.organizationId]);

	useEffect(() => {
		let radiantscript, vodjs, advertjs;
		if (!loading) {
			if (chosenItem && props.isSerie && !chosenItem.serie) {
				// finding ffolder name and id of series asset
				const CancelToken = axios.CancelToken;
				const source = CancelToken.source();
				const fetchChosenItem = async () => {
					const token5 = createAssetIdToken(props.organizationId, asset, language, key);

					const res = await getAsset(
						props.organizationId,
						asset,
						token5,
						language,
						assetProperty,
						user,
						source
					);
					console.log(res, "res assets");
					setChosenItem(res);
				};
				fetchChosenItem();
			}

			if (props.secretKey && props.itemId && chosenItem) {
				const createScript = () => {
					radiantscript = document.createElement("script");
					vodjs = document.createElement("script");
					advertjs = document.createElement("script");

					radiantscript.src = "//cdn.radiantmediatechs.com/rmp/8.4.0/js/rmp.min.js";
					//"https://cdn.radiantmediatechs.com/rmp/6.4.11/js/rmp.min.js";
					radiantscript.async = true;

					// vodjs.src = 'https://my.icareus.com/lib/js/v4/players/vod.js';

					vodjs.async = true;
					vodjs.src = "https://staging1.icareus.com/lib/js/players/v6/players/vod.js";

					advertjs.async = true;
					advertjs.src = "https://staging1.icareus.com/lib/js/players/v6/players/adverts.js";

					document.body.appendChild(radiantscript);
					document.body.appendChild(vodjs);
					document.body.appendChild(advertjs);
				};
				createScript();

				window._icareus = {};

				window._icareus.companyId = props.companyId;

				window._icareus.groupId = props.groupId;

				window._icareus.organizationId = props.organizationId;

				window._icareus.itemId = props.itemId;

				window._icareus.host = "https://suite.icareus.com";
				window._icareus.playerId = "rmpPlayer";
				window._icareus.playerType = "radiant";
				window._icareus.playerSetup = "vodJSCallback";
				window._icareus.playerAutoStart = true;
				window._icareus.userId = props.userId;

				// VOD ADVERT
				window._icareus.campaignId = props.campaignIDD;
				// window._icareus.campaignId = 133485137;
				window._icareus.useAdvertsLibrary = props.campaignIDD ? true : false;
				window._icareus.advertVOD = props.campaignIDD ? true : false;
				// default 30
				window._icareus.applicationTypeId = 30;
				window._icareus.applicationId = 263290738;

				window._icareus.token = createAssetIdToken(
					props.organizationId,
					props.itemId,
					false,
					props.secretKey
				);
				console.log(window);

				window._icareus.gaLabel = ` ${
					chosenItem?.name ? chosenItem.name.toUpperCase() : chosenItem.title.toUpperCase()
				}[${props.itemId}]`;

				window._icareus.gaCategory = props.isSerie ? "Series" : "Videos";
				window._icareus.gatrackingId = "UA-82995370-2";
				// window._icareus.gatrackingId = 'G-XS276N8EC7';
				window._icareus.isSerie = props.isSerie ? props.isSerie : false;
				console.log(`object series`, `${chosenItem.serie?.title}[${chosenItem.serie?.id}]`);

				window._icareus.gaFolderLabel = props.isSerie
					? `${chosenItem.serie?.title}[${chosenItem.serie?.id}]`
					: chosenItem.folders && chosenItem.folders.length > 0
					? `${chosenItem.folders[0].name}/${chosenItem.folders.id}`
					: "Demo Title For Videos";

				window._icareus.gaFolderCategory = "Folder";

				const backButtonHandler = () => {
					if (props.backRoute === undefined) {
						history.goBack();
					} else if (props.backRoute === "hidden") {
						props.setShowPlayer("wantedHidden");
					} else {
						history.push(props.backRoute);
					}
					//props.backRoute ? history.push(props.backRoute) :
				};

				// for the below google analytics we need categoryId and categoryName of series/videos assets

				// Then we define a custom module - in this case a 10s rewind module
				const backCustomModule = {
					hint: t("videoPlayer.close"), // Then name of the module - will show as hint within player UI
					svg: getImageByKey("symbolX"), // Then SVG icon for the module
					svgHover: getImageByKey("symbolX"), // TODO: Image from /images, not web url
					// An optional second icon that can be displayed when the module is hovered
					callback: function () {
						// Our callback function
						backButtonHandler();
					},
				};

				let settings;
				console.log(window.location.hostname);

				window.vodJSCallback = function () {
					settings = {
						// labels: labels,
						detectViewerLanguage: false,

						licenseKey: state.licenseKey,
						src: window._icareus.sources,
						// ccFiles: window._icareus.subtitles,
						ccFiles: window._icareus.rmpSubtitles,

						width: state.width,
						height: state.height,

						pip: false, // ELONET DOES NOT WANT IT
						// if autoHeightMode is true -<width is set to 100% and height adjusted accordingly

						autoHeightMode: true,
						autoHeightModeRatio: state.autoHeightModeRatio,

						// The 4 player skins ('s1', 's2', 'outstream', 'tv') can easily be colorized using the following player settings.
						skin: state.skin,
						// This setting will colorize the background of the skin. Default to ''.

						skinBackgroundColor: state.skinBackgroundColor,
						skinButtonColor: state.skinButtonColor,
						skinAccentColor: state.skinAccentColor,

						speed: state.speed,
						automaticFullscreenOnLandscape: state.automaticFullscreenOnLandscape,
						sharing: state.sharing,
						autoplay: state.autoplay,

						ads: props.campaignIDD ? true : false,

						// logo start

						logo: state.logo,

						logoPosition: state.logoPosition,
						logoLoc: state.logoLoc,

						logoWatermark: state.logoWatermark,
						// logo ends
						// allowLocalStorage: false,

						adTagUrl: window._icareus.videoVastURL,

						contentMetadata: {
							title: asset?.title || asset?.name || "UUSIKAUPUNKI",

							description: asset?.description || null,

							poster: [
								state.containerbackgroundImage
									? state.containerbackgroundImage
									: window._icareus.thumbnail,
							],
						},

						// preload auto will load more video chunks
						preload: "auto",

						asyncElementID: rmpPlayer.current.id,
						mute: false,

						ccFontSize: dimension?.width > 1620 ? 3 : dimension?.width > 599 ? 2 : 1,
					};
					window._icareus.playerObject.init({ ...settings });
				};

				return () => {
					document.body.removeChild(radiantscript);
					document.body.removeChild(vodjs);
					document.body.removeChild(advertjs);
				};
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	return (
		// you may set the width even in the parent component to make these values as variables
		<div ref={rmpPlayer} id="rmpPlayer"></div>
	);
}
