import { API_Server_Number } from "../configs/config_settings.json";

//this is dig out RPM player config from our API respond config
export const getPlayerSettings = (confData) => {
    console.log("cong: ", confData);

    let API_Server = "https://suiterc.icareus.com";
    if (API_Server_Number === 1) {
        API_Server = "https://suite.icareus.com";
    } else if (API_Server_Number === 2) {
        API_Server = "https://suite.icareus.com";
    }

    let externalSettings = {};

    const imagePre = API_Server.includes("suiterc")
        ? "https://suiterc.icareus.com/image/image_gallery?img_id="
        : "https://suite.icareus.com/image/image_gallery?img_id=";

    const titles = {
        autoplay: "autoplay",
        "player-logo-location": "logoLoc",
        "player-logo-watermark": "logoWatermark",
        skin: "skin",
        "skin-background-color": "skinBackgroundColor",
        "skin-button-color": "skinButtonColor",
        "skin-accent-color": "skinAccentColor",
    };

    confData?.forEach((confRow) => {
        if (titles?.[confRow?.title] && confRow?.value !== "") {
            const setValue = confRow.title.match(/color/)
                ? confRow.value.replace("#", "")
                : confRow.value;
            externalSettings[titles[confRow.title]] = setValue;
        } else if (confRow?.title === "aspect-ratio" && confRow?.value !== "") {
            if (confRow?.value !== "auto") {
                const aspects = confRow.value.split(":");
                externalSettings["autoHeightModeRatio"] =
                    Number(aspects[0]) / Number(aspects[1]);
            }
        } else if (
            confRow?.title === "show-speed-settings" &&
            confRow.value === true
        ) {
            externalSettings["speed"] = true;
            externalSettings["speedRates"] = [0.25, 0.5, 1, 1.5, 2, 4];
        } else if (
            confRow?.title === "player-share-buttons" &&
            confRow.value === true
        ) {
            externalSettings["sharing"] = true;
            externalSettings["sharingNetworks"] = [
                "facebook",
                "twitter",
                "linkedin",
            ];
        } else if (
            confRow?.title === "player-logo-image" &&
            confRow.value !== ""
        ) {
            externalSettings["logo"] =
                imagePre + confRow.value + "&extension=.png";
        } else if (confRow?.title === "logo-position" && confRow.value !== "") {
            const valueList = [
                "topleft",
                "topright",
                "bottomright",
                "bottomleft",
            ];
            externalSettings["logoPosition"] = valueList[confRow.value * 1];
        }
    });

    console.log("EXT: ", externalSettings);

    return externalSettings;
};
